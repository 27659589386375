<template>
  <div class="wrapper">
    <b-card no-body bg-variant="dark">
      <div class="animated fadeIn">
        <b-row>
          <b-col cols="12">
            <p style="color: #6ed0c7; font-size: 16px; text-align: center;">
              <span>{{ $t('wdm16.11054') }}</span>
            </p>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" md="6">
            <img slot="img" class="d-block img-fluid w-100" width="1024" height="480" src="../../../static/img/slider/wisdomeratimeline.png" alt="image slot">
          </b-col>
          <b-col sm="12" md="6">
            <b-row>
              <b-col cols="12">
                <span style="color: white">
                <p>{{ $t('wdm16.11055') }}</p>
                <p>{{ $t('wdm16.11056') }}</p>
                <p>{{ $t('wdm16.11057') }}</p>
                <p>{{ $t('wdm16.11058') }}</p>
              </span>
              </b-col>
            </b-row>
            <!-- xxxxxxxxxxxxxxxxxxxxxxxxxxxxx -->
          </b-col>
        </b-row>
        <br>
        <b-row style="border-top: solid 1px white;">
          <b-col sm="12" md="3">
            <div class="wisdombox" style="height: 300px; margin: 20px;">
              <div class="wisdombox-inner">
                <div class="wisdombox-front" style="height: 100%; text-align: center;">
                  <span style="color: #6ed0c7; font-size: 16px;">{{ $t('wdm16.11062') }}</span>
                  <img src="@/icon/1567420.png" style="border-radius: 10px; width: 100%;">
                </div>
                <div class="wisdombox-back" style="height: 100%; color: white; text-align: center; font-size: 14px;">
                  <img src="@/icon/1567420.png" style="border-radius: 10px; width: 100px;">
                  <br>
                  <span>{{ $t('wdm16.11059') }}</span>
                </div>
              </div>
            </div>
          </b-col>
          <b-col sm="12" md="3">
            <div class="wisdombox" style="height: 300px; margin: 20px;">
              <div class="wisdombox-inner">
                <div class="wisdombox-front" style="height: 100%; text-align: center;">
                  <span style="color: #6ed0c7; font-size: 16px;">{{ $t('wdm16.11063') }}</span>
                  <img src="@/icon/2134924.png" style="border-radius: 10px; width: 100%;">
                </div>
                <div class="wisdombox-back" style="height: 100%; color: white; text-align: center; font-size: 14px;">
                  <img src="@/icon/2134924.png" style="border-radius: 10px; width: 100px;">
                  <br>
                  <span>{{ $t('wdm16.11060') }}</span>
                </div>
              </div>
            </div>
          </b-col>
          <b-col sm="12" md="3">
            <div class="wisdombox" style="height: 300px; margin: 20px;">
              <div class="wisdombox-inner">
                <div class="wisdombox-front" style="height: 100%; text-align: center;">
                  <span style="color: #6ed0c7; font-size: 16px;">{{ $t('wdm16.11063') }}</span>
                  <img src="@/icon/2940373.png" style="border-radius: 10px; width: 100%;">
                </div>
                <div class="wisdombox-back" style="height: 100%; color: white; text-align: center; font-size: 14px;">
                  <img src="@/icon/2940373.png" style="border-radius: 10px; width: 100px;">
                  <br>
                  <span>{{ $t('wdm16.11064') }}</span>
                </div>
              </div>
            </div>
          </b-col>
          <b-col sm="12" md="3">
            <div class="wisdombox" style="height: 300px; margin: 20px;">
              <div class="wisdombox-inner">
                <div class="wisdombox-front" style="height: 100%; text-align: center;">
                  <span style="color: #6ed0c7; font-size: 16px;">{{ $t('wdm16.11065') }}</span>
                  <img src="@/icon/2028935.png" style="border-radius: 10px; width: 100%;">
                </div>
                <div class="wisdombox-back" style="height: 100%; color: white; text-align: center; font-size: 14px;">
                  <img src="@/icon/2028935.png" style="border-radius: 10px; width: 100px;">
                  <br>
                  <span>{{ $t('wdm16.11066') }}</span>
                </div>
              </div>
            </div>
          </b-col>
          <b-col sm="12" md="3">
            <div class="wisdombox" style="height: 300px; margin: 20px;">
              <div class="wisdombox-inner">
                <div class="wisdombox-front" style="height: 100%; text-align: center;">
                  <span style="color: #6ed0c7; font-size: 16px;">{{ $t('wdm16.11067') }}</span>
                  <img src="@/icon/2280326.png" style="border-radius: 10px; width: 100%;">
                </div>
                <div class="wisdombox-back" style="height: 100%; color: white; text-align: center; font-size: 14px;">
                  <img src="@/icon/2280326.png" style="border-radius: 10px; width: 100px;">
                  <br>
                  <span>{{ $t('wdm16.11068') }}</span>
                </div>
              </div>
            </div>
          </b-col>
          <b-col sm="12" md="3">
            <div class="wisdombox" style="height: 300px; margin: 20px;">
              <div class="wisdombox-inner">
                <div class="wisdombox-front" style="height: 100%; text-align: center;">
                  <span style="color: #6ed0c7; font-size: 16px;">{{ $t('wdm16.11069') }}</span>
                  <img src="@/icon/2486930.png" style="border-radius: 10px; width: 100%;">
                </div>
                <div class="wisdombox-back" style="height: 100%; color: white; text-align: center; font-size: 14px;">
                  <img src="@/icon/2486930.png" style="border-radius: 10px; width: 100px;">
                  <br>
                  <span>{{ $t('wdm16.11070') }}</span>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
        <br>
      </div>
      <web-bottom></web-bottom>
    </b-card>
  </div>
</template>

<script>
import WebBottom from '@/components/widgets/WebBottom';
export default {
  name: 'jumbotrons',
  components: {
    WebBottom
  }
}

</script>

